import { useState } from "react"
import { motion } from "framer-motion"
import { addPropertyControls, ControlType } from "framer"

// Welcome to Code in Framer
// Get Started: https://www.framer.com/docs/guides/

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/docs/guides/auto-sizing
 *
 * @framerSupportedLayoutWidth any
 * @framerSupportedLayoutHeight any
 */
export default function Justified_text(props) {
    const { tint, font, text, style, fsize, lheight } = props

    return (
        <motion.div style={{ ...style, ...justifyText }}>
            <motion.div
                style={{
                    fontSize: fsize,
                    lineHeight: lheight,
                    color: tint,
                    fontFamily: `"${font}", sans-serif`,
                }}
            >
                {text}
            </motion.div>
        </motion.div>
    )
}

Justified_text.defaultProps = {
    tint: "#000000",
    fsize: 55,
    lheight: 1,
}

addPropertyControls(Justified_text, {
    lheight: {
        title: "Line Height",
        type: ControlType.Number,
        min: 0.1,
        max: 10,
        step: 0.05,
    },
    fsize: {
        title: "Size",
        type: ControlType.Number,
    },
    text: {
        title: "Text",
        type: ControlType.String,
        displayTextArea: true,
        defaultValue: "this is some text that can be justified",
    },
    tint: {
        title: "Tint",
        type: ControlType.Color,
    },
    font: {
        type: ControlType.Enum,
        defaultValue: "Istok Web",
        options: ["Arial", "Times new roman", "Inter", "Istok Web", "your own"],
        optionTitles: [
            "Arial",
            "Times New Roman",
            "Inter",
            "Istok Web",
            "your own",
        ],
    },
})

const justifyText = {
    textAlign: "justify",
}
